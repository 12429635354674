import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { getAllNotification } from "../../api/notification";
import useAccess from '../../api/useAccess';

const NotificationsLists = () => {
	const [data, setData] = useState([]);
	useEffect(() => {
		dataFetch();
	}, [])
	const dataFetch = async () => {
		let data = await getAllNotification();
		setData(data.records)
	}




	return (
		<>
			<div className="d-flex align-items-center mb-4 flex-wrap">
				<h4 className="fs-20 font-w600  me-auto">Notifications Lists</h4>
				<Link to="/notification" className="btn btn-primary me-3 btn-sm">
                        <i className="ti-arrow-left me-2"></i>Back to Listing
                </Link>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>S.No</th>
									{/* <th>Notification Type</th> */}
									<th>Subject</th>
								</tr>
							</thead>
							<tbody>
								{(data || []).map((cms, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										{/* <td><input type="checkbox" value="VO" name={`view_own_${item.id}`} onChange={handleChange} checked={formState[`view_own_${item.id}`] || false} disabled={item.view_own === "1"} /> </td> */}
										<td>{cms}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}
export default NotificationsLists;