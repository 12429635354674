import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { recruiterListAPI, deleteRecruiter } from "../../api/recruiter";
import NewR from "./NewR";
import EditR from "./EditR";
import _ from "lodash";

const RLists = () => {
  const [addCard, setAddCard] = useState(false);
  const [Events, setEvents] = useState([]);
  const [recruiterType] = useState([
    { id: 1, name: "Regular Recruiter" },
    { id: 2, name: "Internship Recruiter" },
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  // Debounced Filter Handler
  const debouncedFilter = useMemo(() => {
    setPage(0);
    return _.debounce(setFilter, 500);
  }, []);

  useEffect(() => {
    dataFetch();
  }, [sortConfig, page]);

  useEffect(() => {
    setPage(1);
    dataFetch();
  }, [filter]);

  const dataFetch = async () => {
    let params = {
      page,
      limit: 10,
      sort_by: sortConfig.key,
      sort_direction: sortConfig.direction,
      keyword: filter,
    };
    let data = await recruiterListAPI(params);
    if (page === 1) {
      setEvents(data);
    } else {
      setEvents((prevEvents) => [...prevEvents, ...data]);
    }
    setHasMore(data.length > 0); // Update hasMore if there's more data to load
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    setSortConfig({ key, direction });
    setPage(1);
    setHasMore(true);
  };

  // Delete data
  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      let res = await deleteRecruiter(contactId);
      if (res?.error) {
        swal("Oops", res.error, "error");
      } else {
        dataFetch();
        swal("Deleted!", "Record has been deleted!", "success");
      }
    }
  };

  // Edit start
  const [editModal, setEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = async (id) => {
    setEditContactId(id);
    setEditModal(true);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };

  // Infinite Scroll Handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      !hasMore
    )
      return;
    setPage((prevPage) => prevPage + 1);
  }, [hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleJobsClick = (e, recruiter_id) => {
    e.preventDefault();
    localStorage.setItem("recruiter_id", recruiter_id);
    localStorage.removeItem("subrecruiter_id");
    navigate("/job_list?type=recruiter");
  };

  const handleSubRClick = (e, recruiter_id) => {
    e.preventDefault();
    localStorage.setItem("rid", recruiter_id);
    navigate("/sub_recruiter_list");
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Recruiter List</h4>
        <div className="d-flex">
          <Link
            to={"#"}
            className="btn btn-primary btn-sm"
            onClick={() => setAddCard(true)}
          >
            <i className="fas fa-plus me-2"></i>Add New Recruiter
          </Link>
        </div>
        <NewR addCard={addCard} setAddCard={setAddCard} dataFetch={dataFetch} />
        <EditR
          editModal={editModal}
          setEditModal={setEditModal}
          dataFetch={dataFetch}
          editid={editContactId}
          setEditContactId={setEditContactId}
        />
      </div>
      <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            placeholder="Filter by keyword"
            className="form-control me-3"
            onChange={(e) => debouncedFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th onClick={() => handleSort("name")}>
                    Name {renderSortIcon("name")}
                  </th>
                  <th onClick={() => handleSort("company_name")}>
                    Company {renderSortIcon("company_name")}
                  </th>
                  <th>Email</th>
                  <th onClick={() => handleSort("recruiter_type")}>
                    Type {renderSortIcon("recruiter_type")}
                  </th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Events?.length > 0 ? (
                  Events?.map((Event, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{Event?.name}</td>
                      <td>{Event?.company_name}</td>
                      <td>{Event.email}</td>
                      <td>
                        {Event.recruiter_type
                          ?.split(",")
                          .map((it) => recruiterType[it - 1].name)
                          .join(", ")}
                      </td>
                      <td>
                        <span
                          className={`badge badge-lg light badge-${
                            Event.published == "1" ? "success" : "danger"
                          }`}
                        >
                          {Event.published == "1" ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <div title="Manage Sub Recruiter" className="mr-2">
                            <Link
                              to={"#"}
                              onClick={(e) => handleSubRClick(e, Event.id)}
                              className="btn btn-secondary light mr-2"
                            >
                              MSR
                            </Link>
                          </div>
                          <div title="View Jobs" className="mr-2">
                            <Link
                              to={"#"}
                              className="btn btn-secondary light mr-2"
                              onClick={(e) => handleJobsClick(e, Event.id)}
                            >
                              View Jobs
                            </Link>
                          </div>

                          <div className="action-buttons d-flex justify-content-end">
                            <Link
                              to={"#"}
                              className="btn btn-secondary light mr-2"
                              onClick={() => handleEditClick(Event.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                  ></path>
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    x="5"
                                    y="20"
                                    width="15"
                                    height="2"
                                    rx="1"
                                  ></rect>
                                </g>
                              </svg>
                            </Link>
                            <Link
                              to={"#"}
                              className="btn btn-danger light"
                              onClick={() => handleDeleteClick(Event.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="100%" className="text-center">
                        {" "}
                        No Record Found.
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RLists;
