import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import {
  getCandidateDetailsAPI,
  updateCandidateAPI,
} from "../../api/candidate";
import {
  getAllSkills,
  getAllIdentity,
  getAllCities,
  uploadImage,
} from "../../api/common";
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const months_new = [
  { id: 1, name: "Jan" },
  { id: 2, name: "Feb" },
  { id: 3, name: "Mar" },
  { id: 4, name: "Apr" },
  { id: 5, name: "May" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Aug" },
  { id: 9, name: "Sep" },
  { id: 10, name: "Oct" },
  { id: 11, name: "Nov" },
  { id: 12, name: "Dec" },
];

const EditCandidate = () => {
  const [candidate, setCandidate] = useState({
    legal_name: "",
    pronoun: "Mr.",
    email: "",
    ph_country_code: "+91",
    phone_number: "",
    wh_country_code: "+91",
    whatsapp_number: "",
    identify_as: "",
    identify_as_other: "", // Added for 'other' identification
    current_profile_role: "",
    key_skills: [], // Changed to array for multi-select
    current_salary: "",
    source_details: "",
    current_location: "",
    prefered_location: "",
    resume_file: "",
    redacted_resume: "",
    lgbt_status: "",
    candidate_status: "",
    interested_in_internships: "1",
    shared_with_circle_identity_as_lgbt: "1",
    total_experience_years: "1",
    total_experience_months: "2",
    current_previous_company: "Test123",
    annual_package: "1",
    about_yourself: "Test hello test update",
    highest_education: "MCA",
    institution_name: "SMU",
    highest_education_passing_month: "11",
    highest_education_passing_year: "2001",
    published: "1",
  });

  const [resumeFile, setResumeFile] = useState(null);
  const [redactedResumeFile, setRedactedResumeFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [skills, setSkills] = useState([]);
  const [cities, setCities] = useState([]);
  const [identityList, setIdentityList] = useState([]);
  const navigate = useNavigate();
  const candidateId = localStorage.getItem("nid");

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      const skillList = await getAllSkills();
      setSkills(skillList);
      const cityList = await getAllCities();
      setCities(cityList);
      const ident = await getAllIdentity();
      setIdentityList(ident);
      const details = await getCandidateDetailsAPI(candidateId);

      let skillArr = details.skills?.split(",");

      let skillsArr = skillList.filter((s) => {
        return skillArr.includes(`${s.id}`);
      });

      setCandidate({
        ...details,
        key_skills: skillsArr?.map((skill) => ({
          value: skill.id,
          label: skill.skill_title,
        })),
      });
    };

    fetchCandidateDetails();
  }, [candidateId]);

  /* const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCandidate((prevCandidate) => ({
      ...prevCandidate,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  */

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setCandidate((prevCandidate) => {
      if (name === "internship_available_months") {
        const selectedMonths = prevCandidate.internship_available_months
          ? prevCandidate.internship_available_months.split(",").map(Number)
          : [];

        if (checked) {
          // Add month to the selected list
          selectedMonths.push(Number(value));
        } else {
          // Remove month from the selected list
          const index = selectedMonths.indexOf(Number(value));
          if (index > -1) selectedMonths.splice(index, 1);
        }

        return {
          ...prevCandidate,
          internship_available_months: selectedMonths.join(","),
        };
      }

      return {
        ...prevCandidate,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setCandidate((prevCandidate) => ({
      ...prevCandidate,
      [name]: values,
    }));
  };

  const handleSkillsChange = (selectedOptions) => {
    setCandidate((prevState) => ({
      ...prevState,
      key_skills: selectedOptions,
    }));
  };

  const handleResumeChange = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadImage(formData);
      if (response != false) {
        setResumeFile(response.data);
      }
    } else {
      alert("Please upload a .pdf or .docx file");
    }
  };

  const handleRedactedResumeChange = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadImage(formData);
      if (response != false) {
        setRedactedResumeFile(response.data);
      }
    } else {
      alert("Please upload a .pdf or .docx file");
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!candidate.legal_name) newErrors.legal_name = "Name is required";
    if (!candidate.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(candidate.email))
      newErrors.email = "Email address is invalid";
    if (!candidate.phone_number)
      newErrors.phone_number = "Phone number is required";
    if (!candidate.identify_as)
      newErrors.identify_as = "Identify As is required";
    if (!candidate.current_profile_role)
      newErrors.current_profile_role = "Current profile role is required";
    if (candidate.key_skills.length === 0)
      newErrors.key_skills = "Key skills are required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    let resumeFileName = candidate.resume_file;
    let redactedResumeFileName = candidate.redacted_resume;

    if (resumeFile) {
      resumeFileName = resumeFile;
    }

    if (redactedResumeFile) {
      redactedResumeFileName = redactedResumeFile;
    }

    const updatedCandidate = {
      ...candidate,
      resume_file: resumeFileName,
      redacted_resume: redactedResumeFileName,
      status: candidate.published,
      key_skills: candidate.key_skills.map((skill) => skill.value).join(","),
    };
    await updateCandidateAPI(candidateId, updatedCandidate);
    navigate("/c_lists");
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h2 className="mb-0">Edit Candidate</h2>
          <Link to="/c_lists" className="btn btn-primary me-3 btn-sm">
            <i className="fas fa-plus me-2"></i>Back to Listing
          </Link>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="pronoun" className="form-label">
                  Pronoun
                </label>
                <select
                  className="form-select"
                  id="pronoun"
                  name="pronoun"
                  value={candidate.pronoun}
                  onChange={handleChange}
                >
                  <option value="She/Her">She/Her</option>
                  <option value="He/Him">He/Him</option>
                  <option value="They/Them">They/Them</option>
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="legal_name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.legal_name ? "is-invalid" : ""
                  }`}
                  id="legal_name"
                  name="legal_name"
                  value={candidate.legal_name}
                  onChange={handleChange}
                  required
                />
                {errors.legal_name && (
                  <div className="invalid-feedback">{errors.legal_name}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  name="email"
                  value={candidate.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>

              <div className="col-md-2 mb-3">
                <label htmlFor="ph_country_code" className="form-label">
                  Country Code
                </label>
                <select
                  className="form-select"
                  id="ph_country_code"
                  name="ph_country_code"
                  value={candidate.ph_country_code}
                  onChange={handleChange}
                >
                  <option value="">Select Country Code</option>
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+91">+91 (India)</option>
                  <option value="+61">+61 (Australia)</option>
                  <option value="+33">+33 (France)</option>
                  <option value="+49">+49 (Germany)</option>
                  <option value="+81">+81 (Japan)</option>
                  <option value="+86">+86 (China)</option>
                  <option value="+7">+7 (Russia)</option>
                  <option value="+39">+39 (Italy)</option>
                  <option value="+55">+55 (Brazil)</option>
                  <option value="+34">+34 (Spain)</option>
                  <option value="+27">+27 (South Africa)</option>
                  <option value="+82">+82 (South Korea)</option>
                  <option value="+65">+65 (Singapore)</option>
                  <option value="+64">+64 (New Zealand)</option>
                  <option value="+52">+52 (Mexico)</option>
                  <option value="+63">+63 (Philippines)</option>
                  <option value="+60">+60 (Malaysia)</option>
                  <option value="+62">+62 (Indonesia)</option>
                  <option value="+971">+971 (United Arab Emirates)</option>
                  <option value="+966">+966 (Saudi Arabia)</option>
                  <option value="+41">+41 (Switzerland)</option>
                  <option value="+351">+351 (Portugal)</option>
                  <option value="+46">+46 (Sweden)</option>
                  <option value="+45">+45 (Denmark)</option>
                  <option value="+31">+31 (Netherlands)</option>
                  <option value="+90">+90 (Turkey)</option>
                  <option value="+98">+98 (Iran)</option>
                  <option value="+964">+964 (Iraq)</option>
                  <option value="+92">+92 (Pakistan)</option>
                  <option value="+20">+20 (Egypt)</option>
                  <option value="+56">+56 (Chile)</option>
                  <option value="+66">+66 (Thailand)</option>
                  <option value="+65">+65 (Singapore)</option>
                  <option value="+94">+94 (Sri Lanka)</option>
                  <option value="+60">+60 (Malaysia)</option>
                  <option value="+234">+234 (Nigeria)</option>
                  <option value="+58">+58 (Venezuela)</option>
                  <option value="+82">+82 (South Korea)</option>
                  <option value="+62">+62 (Indonesia)</option>
                  <option value="+966">+966 (Saudi Arabia)</option>
                  <option value="+961">+961 (Lebanon)</option>
                  <option value="+357">+357 (Cyprus)</option>
                  <option value="+385">+385 (Croatia)</option>
                  <option value="+216">+216 (Tunisia)</option>
                  <option value="+380">+380 (Ukraine)</option>
                  <option value="+48">+48 (Poland)</option>
                  <option value="+30">+30 (Greece)</option>
                  <option value="+375">+375 (Belarus)</option>
                  <option value="+359">+359 (Bulgaria)</option>
                  <option value="+45">+45 (Denmark)</option>
                  <option value="+372">+372 (Estonia)</option>
                  <option value="+358">+358 (Finland)</option>
                  <option value="+36">+36 (Hungary)</option>
                  <option value="+353">+353 (Ireland)</option>
                  <option value="+352">+352 (Luxembourg)</option>
                  <option value="+48">+48 (Poland)</option>
                  <option value="+40">+40 (Romania)</option>
                  <option value="+421">+421 (Slovakia)</option>
                  <option value="+386">+386 (Slovenia)</option>
                  <option value="+381">+381 (Serbia)</option>
                  <option value="+371">+371 (Latvia)</option>
                  <option value="+370">+370 (Lithuania)</option>
                </select>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="phone_number" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.phone_number ? "is-invalid" : ""
                  }`}
                  id="phone_number"
                  name="phone_number"
                  value={candidate.phone_number}
                  onChange={handleChange}
                  required
                />
                {errors.phone_number && (
                  <div className="invalid-feedback">{errors.phone_number}</div>
                )}
              </div>
              <div className="col-md-2 mb-3">
                <label htmlFor="wh_country_code" className="form-label">
                  Country Code
                </label>
                <select
                  className="form-select"
                  id="wh_country_code"
                  name="wh_country_code"
                  value={candidate.wh_country_code}
                  onChange={handleChange}
                >
                  <option value="">Select Country Code</option>
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+91">+91 (India)</option>
                  <option value="+61">+61 (Australia)</option>
                  <option value="+33">+33 (France)</option>
                  <option value="+49">+49 (Germany)</option>
                  <option value="+81">+81 (Japan)</option>
                  <option value="+86">+86 (China)</option>
                  <option value="+7">+7 (Russia)</option>
                  <option value="+39">+39 (Italy)</option>
                  <option value="+55">+55 (Brazil)</option>
                  <option value="+34">+34 (Spain)</option>
                  <option value="+27">+27 (South Africa)</option>
                  <option value="+82">+82 (South Korea)</option>
                  <option value="+65">+65 (Singapore)</option>
                  <option value="+64">+64 (New Zealand)</option>
                  <option value="+52">+52 (Mexico)</option>
                  <option value="+63">+63 (Philippines)</option>
                  <option value="+60">+60 (Malaysia)</option>
                  <option value="+62">+62 (Indonesia)</option>
                  <option value="+971">+971 (United Arab Emirates)</option>
                  <option value="+966">+966 (Saudi Arabia)</option>
                  <option value="+41">+41 (Switzerland)</option>
                  <option value="+351">+351 (Portugal)</option>
                  <option value="+46">+46 (Sweden)</option>
                  <option value="+45">+45 (Denmark)</option>
                  <option value="+31">+31 (Netherlands)</option>
                  <option value="+90">+90 (Turkey)</option>
                  <option value="+98">+98 (Iran)</option>
                  <option value="+964">+964 (Iraq)</option>
                  <option value="+92">+92 (Pakistan)</option>
                  <option value="+20">+20 (Egypt)</option>
                  <option value="+56">+56 (Chile)</option>
                  <option value="+66">+66 (Thailand)</option>
                  <option value="+65">+65 (Singapore)</option>
                  <option value="+94">+94 (Sri Lanka)</option>
                  <option value="+60">+60 (Malaysia)</option>
                  <option value="+234">+234 (Nigeria)</option>
                  <option value="+58">+58 (Venezuela)</option>
                  <option value="+82">+82 (South Korea)</option>
                  <option value="+62">+62 (Indonesia)</option>
                  <option value="+966">+966 (Saudi Arabia)</option>
                  <option value="+961">+961 (Lebanon)</option>
                  <option value="+357">+357 (Cyprus)</option>
                  <option value="+385">+385 (Croatia)</option>
                  <option value="+216">+216 (Tunisia)</option>
                  <option value="+380">+380 (Ukraine)</option>
                  <option value="+48">+48 (Poland)</option>
                  <option value="+30">+30 (Greece)</option>
                  <option value="+375">+375 (Belarus)</option>
                  <option value="+359">+359 (Bulgaria)</option>
                  <option value="+45">+45 (Denmark)</option>
                  <option value="+372">+372 (Estonia)</option>
                  <option value="+358">+358 (Finland)</option>
                  <option value="+36">+36 (Hungary)</option>
                  <option value="+353">+353 (Ireland)</option>
                  <option value="+352">+352 (Luxembourg)</option>
                  <option value="+48">+48 (Poland)</option>
                  <option value="+40">+40 (Romania)</option>
                  <option value="+421">+421 (Slovakia)</option>
                  <option value="+386">+386 (Slovenia)</option>
                  <option value="+381">+381 (Serbia)</option>
                  <option value="+371">+371 (Latvia)</option>
                  <option value="+370">+370 (Lithuania)</option>
                </select>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="whatsapp_number" className="form-label">
                  WhatsApp Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="whatsapp_number"
                  name="whatsapp_number"
                  value={candidate.whatsapp_number}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="identify_as" className="form-label">
                  Identify As
                </label>
                <select
                  className={`form-select ${
                    errors.identify_as ? "is-invalid" : ""
                  }`}
                  id="identify_as"
                  name="identify_as"
                  value={candidate.identify_as}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select...</option>
                  {identityList.map((identity) => (
                    <option key={identity.id} value={identity.id}>
                      {identity.identify_name}
                    </option>
                  ))}
                </select>
                {errors.identify_as && (
                  <div className="invalid-feedback">{errors.identify_as}</div>
                )}
              </div>
              {candidate.identify_as === "11" && (
                <div className="col-md-6 mb-3">
                  <label htmlFor="identify_as_other" className="form-label">
                    Please Specify
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.identify_as_other ? "is-invalid" : ""
                    }`}
                    id="identify_as_other"
                    name="identify_as_other"
                    value={candidate.identify_as_other}
                    onChange={handleChange}
                    required
                  />
                  {errors.identify_as_other && (
                    <div className="invalid-feedback">
                      {errors.identify_as_other}
                    </div>
                  )}
                </div>
              )}
              <div className="col-md-6 mb-3">
                <label htmlFor="current_profile_role" className="form-label">
                  Current Profile Role
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.current_profile_role ? "is-invalid" : ""
                  }`}
                  id="current_profile_role"
                  name="current_profile_role"
                  value={candidate.current_profile_role}
                  onChange={handleChange}
                  required
                />
                {errors.current_profile_role && (
                  <div className="invalid-feedback">
                    {errors.current_profile_role}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="key_skills" className="form-label">
                  Key Skills
                </label>
                <Select
                  isMulti
                  name="key_skills"
                  options={skills.map((skill) => ({
                    value: skill.id,
                    label: skill.skill_title,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={candidate.key_skills}
                  onChange={handleSkillsChange}
                  required
                />
                {errors.key_skills && (
                  <div className="invalid-feedback">{errors.key_skills}</div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="current_salary" className="form-label">
                  Current Salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="current_salary"
                  name="current_salary"
                  value={candidate.current_salary}
                  onChange={handleChange}
                />
              </div>
             {/* <div className="col-md-6 mb-3">
                <label htmlFor="source_details" className="form-label">
                  Source Details
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="source_details"
                  name="source_details"
                  value={candidate.source_details}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col-md-6 mb-3">
                <label htmlFor="current_location" className="form-label">
                  Current Location
                </label>
                <select
                  className="form-select"
                  id="current_location"
                  name="current_location"
                  value={candidate.current_location}
                  onChange={handleChange}
                >
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="prefered_location" className="form-label">
                  Preferred Location
                </label>
                <select
                  className="form-select"
                  id="prefered_location"
                  name="prefered_location"
                  value={candidate.prefered_location}
                  onChange={handleChange}
                >
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label
                  htmlFor="interested_in_internships"
                  className="form-label"
                >
                  Interested in Internships
                </label>
                <select
                  className="form-select"
                  id="interested_in_internships"
                  name="interested_in_internships"
                  value={candidate.interested_in_internships}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              {candidate.interested_in_internships == "1" && (
                <div className="col-md-12 mb-3">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="over_eighteen_years"
                        className="form-label"
                      >
                        Are you over 18 years of age?
                      </label>
                      <select
                        className="form-select"
                        id="over_eighteen_years"
                        name="over_eighteen_years"
                        value={candidate.over_eighteen_years}
                        onChange={handleChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        Available Internship Months
                      </label>
                      <div className="row">
                        {months_new.map((month) => (
                          <div className="col-md-3" key={month.id}>
                            <input
                              type="checkbox"
                              id={`month_${month.id}`}
                              name="internship_available_months"
                              value={month.id}
                              checked={
                                candidate.internship_available_months
                                  ?.split(",")
                                  .map(Number)
                                  .includes(month.id) || false
                              }
                              onChange={handleChange}
                            />
                            <label htmlFor={`month_${month.id}`}>
                              &nbsp;{month.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="aggregate_percentage"
                        className="form-label"
                      >
                        Aggregate percentage
                      </label>
                      <select
                        className="form-select"
                        id="aggregate_percentage"
                        name="aggregate_percentage"
                        value={candidate.aggregate_percentage}
                        onChange={handleChange}
                      >
                        <option value="0" hidden>
                          Choose Options
                        </option>
                        <option value="Upto 50.00%">Upto 50.00%</option>
                        <option value="50.01 to 60.00%">50.01 to 60.00%</option>
                        <option value="60.01 to 70.00%">60.01 to 70.00%</option>
                        <option value="70.01 to 80.00%">70.01 to 80.00%</option>
                        <option value="80.01 to 90.00%">80.01 to 90.00%</option>
                        <option value="90.01 to 100.00%">
                          90.01 to 100.00%
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="shared_with_circle_identity_as_lgbt"
                  className="form-label"
                >
                  Shared with Circle as LGBT
                </label>
                <select
                  className="form-select"
                  id="shared_with_circle_identity_as_lgbt"
                  name="shared_with_circle_identity_as_lgbt"
                  value={candidate.shared_with_circle_identity_as_lgbt}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="total_experience_years" className="form-label">
                  Total Experience (Years)
                </label>
                <select
                  className="form-select"
                  id="total_experience_years"
                  name="total_experience_years"
                  value={candidate.total_experience_years}
                  onChange={handleChange}
                >
                  {[...Array(51).keys()].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="total_experience_months" className="form-label">
                  Total Experience (Months)
                </label>
                <select
                  className="form-select"
                  id="total_experience_months"
                  name="total_experience_months"
                  value={candidate.total_experience_months}
                  onChange={handleChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label
                  htmlFor="current_previous_company"
                  className="form-label"
                >
                  Current/Previous Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="current_previous_company"
                  name="current_previous_company"
                  value={candidate.current_previous_company}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="annual_package" className="form-label">
                  Annual Package
                </label>
                <select
                  className="form-select"
                  id="annual_package"
                  name="annual_package"
                  value={candidate.annual_package}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="about_yourself" className="form-label">
                  About Yourself
                </label>
                <textarea
                  className="form-control"
                  id="about_yourself"
                  name="about_yourself"
                  rows="3"
                  value={candidate.about_yourself}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="highest_education" className="form-label">
                  Highest Education
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="highest_education"
                  name="highest_education"
                  value={candidate.highest_education}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="institution_name" className="form-label">
                  Institution Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="institution_name"
                  name="institution_name"
                  value={candidate.institution_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="highest_education_passing_month"
                  className="form-label"
                >
                  Passing Month
                </label>
                <select
                  className="form-select"
                  id="highest_education_passing_month"
                  name="highest_education_passing_month"
                  value={candidate.highest_education_passing_month}
                  onChange={handleChange}
                >
                  {months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="highest_education_passing_year"
                  className="form-label"
                >
                  Passing Year
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="highest_education_passing_year"
                  name="highest_education_passing_year"
                  value={candidate.highest_education_passing_year}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="candidate_status" className="form-label">
                  Candidate Status
                </label>
                <select
                  className="form-select"
                  id="candidate_status"
                  name="candidate_status"
                  value={candidate.candidate_status}
                  onChange={handleChange}
                >
                  <option value="1">CV Shortlisted</option>
                  <option value="2">Reject</option>
                  <option value="3">On hold</option>
                  <option value="4">Offered</option>
                  <option value="5">DND</option>
                  <option value="6">Others</option>
                  <option value="7">In process</option>
                  <option value="8">Interview Scheduled</option>
                  <option value="9">Viewed(Action Pending)</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lgbt_status" className="form-label">
                  LGBT Status
                </label>
                <select
                  className="form-select"
                  id="lgbt_status"
                  name="lgbt_status"
                  value={candidate.lgbt_status}
                  onChange={handleChange}
                >
                  <option value="0">Pending</option>
                  <option value="1">Confirmed</option>
                  <option value="2">Rejected</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="resume_file" className="form-label">
                  Resume
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="resume_file"
                  name="resume_file"
                  onChange={handleResumeChange}
                  accept=".pdf,.docx"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="redacted_resume" className="form-label">
                  Redacted Resume
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="redacted_resume"
                  name="redacted_resume"
                  onChange={handleRedactedResumeChange}
                  accept=".pdf,.docx"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Published Status</label>
                <div>
                  <input
                    type="radio"
                    id="active"
                    name="published"
                    value="1"
                    checked={candidate.published == "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="active" className="form-check-label mx-2">
                    Active
                  </label>
                  <input
                    type="radio"
                    id="inactive"
                    name="published"
                    value="0"
                    checked={candidate.published != "1"}
                    onChange={handleChange}
                  />
                  <label htmlFor="inactive" className="form-check-label mx-2">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCandidate;
