import React,{useState, useEffect } from "react";
import { getAllIdentity } from "../../api/common";
const IdentityMatrix = (props)=>{
    const {data} = props;
    const [identity, setIdentity] = useState([]);

    useEffect(()=>{
        fetchData();
    }, [])

    const fetchData=async()=>{
        let da = await getAllIdentity();
        setIdentity(da);
    }
    return <>
           
           <div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>Skill</th>
                                    {
                                      identity?.map((item)=>{
                                        return <><th>{item.identify_name}</th></>
                                      })     
                                    }
								</tr>
							</thead>
							<tbody>
								{data?.map((d, index) => (
									<tr key={index}>
										<td>{d.skill_title}</td>
                                        {
                                            d?.identifies?.map((item)=>{
                                                return <><td>{item.candidates}</td></>
                                            })
                                        }
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>    
          </>
}

export default IdentityMatrix;