import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import {
  jobListAPI,
  deleteJob,
  jobCompanyList,
  verifyThisJob,
} from "../../api/job";
import { getAllSkills, getAllIdentity, getAllCities } from "../../api/common";
import useAccess from "../../api/useAccess";
import { job_types } from "../../common/job_types";
//import {renderSortIcon} from "../../common/sort_by";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const JobList = () => {
  const query = useQuery();
  const paramValue = query.get("type");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const { fetchAccess } = useAccess();
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({
    company_id: "",
    job_title: "",
    location: "",
    skill_id: "",
    job_type: "",
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const navigate = useNavigate();
  const observer = useRef();
  const permission = fetchAccess();
  const [skillList, setSkillList] = useState([]);
  const [identityList, setIdentityList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isloading, setIsLoading] = useState(false);

  var recruiter_id = "";
  var subrecruiter_id = "";
  if (paramValue == "subrecruiter") {
    recruiter_id = "";
    subrecruiter_id = localStorage.getItem("subrecruiter_id")
      ? localStorage.getItem("subrecruiter_id")
      : "";
  } else if (paramValue == "recruiter") {
    recruiter_id = localStorage.getItem("recruiter_id")
      ? localStorage.getItem("recruiter_id")
      : "";
    subrecruiter_id = "";
  } else {
    recruiter_id = "";
    subrecruiter_id = "";
  }

  const fetchFilterData = async () => {
    let s = await getAllSkills();
    let ident = await getAllIdentity();
    let c = await getAllCities();
    const cmp = await jobCompanyList();
    setCompanies(cmp);
    setSkillList(s);
    setIdentityList(ident);
    setCitiesList(c);
  };

  const dataFetch = async (reset = false) => {
    let params = {
      page,
      limit: 10,
      ...filters,
      sort_by: sortConfig.key,
      sort_direction: sortConfig.direction,
      recruiter_id: recruiter_id,
      subrecruiter_id: subrecruiter_id,
    };
    let response = await jobListAPI(params);
    if (response?.length < 10) setHasMore(false); // Assuming 10 items per page
    setData((prevData) => (reset ? response : [...prevData, ...response]));
  };

  useEffect(() => {
    dataFetch(true);
  }, [filters, sortConfig]);

  useEffect(() => {
    if (page !== 1) {
      dataFetch();
    }
  }, [page]);

  useEffect(() => {
    fetchFilterData();
  }, []);

  const lastJobElementRef = useRef();
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    }, options);

    if (lastJobElementRef.current) {
      observer.current.observe(lastJobElementRef.current);
    }

    return () => {
      if (observer.current && lastJobElementRef.current) {
        observer.current.unobserve(lastJobElementRef.current);
      }
    };
  }, [hasMore]);

  const handleEditClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("nid", id);
    navigate("/edit_Job");
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setPage(1);
    setHasMore(true);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setPage(1);
    setHasMore(true);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "asc") {
        return <span>▲</span>;
      } else if (sortConfig.direction === "desc") {
        return <span>▼</span>;
      }
    }
    return <span>↕</span>;
  };

  const handleViewClick = async (event, id) => {
    event.preventDefault();
    localStorage.setItem("vid", id);
    navigate("/view_job");
  };

  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      await deleteJob(contactId);
      dataFetch(true);
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };
  const verifyThisUser = async (id) => {
    setIsLoading(true);
    try {
      const updatedData = {
        verified_status: 1,
        id: id,
      };
      let res = await verifyThisJob(updatedData);
      if (res !== false) {
        if (res?.error && res.error !== "undefined") {
          console.log("error", res.error);
          swal("Error", res.error, "error");
        } else {
          setPage(0);
          dataFetch(true);
          swal("Success", "Job updated successfully", "success");
        }
      } else {
        swal(
          "Error",
          "There was an error updating the job. Please contact administrator!",
          "error"
        );
      }
    } catch (error) {
      swal("Error", "There was an error updating the job", "error");
    } finally {
      setIsLoading(false);
    }
  };
  // function: condition base HTML for verified user
  const isVerifiedUserHTML = (jobData) => {
    return jobData?.verified_status || false ? (
      <button className="btn btn-success">verified</button>
    ) : (
      <button
        className="btn btn-primary"
        onClick={() => verifyThisUser(jobData.id)}
      >
        Verify
      </button>
    );
  };

  const handleApplicantsClick = (e, job_id) => {
    e.preventDefault();
    localStorage.setItem("job_id", job_id);
    localStorage.removeItem("event_id");
    navigate("/c_lists?type=job");
  };
  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Job List</h4>
        {paramValue==null && permission.a === true ? (
          <Link to={"/new_Job"} className="btn btn-primary me-3 btn-sm">
            <i className="fas fa-plus me-2"></i>Add New Job
          </Link>
        ) : null}

      </div>
      <div className="d-flex justify-content-end mb-4">
        <div className="d-flex gap-3">
          <select
            className="form-select"
            name="company_id"
            value={filters.company_id}
            onChange={handleFilterChange}
          >
            <option value="">Select Company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.company_name}
              </option>
            ))}
          </select>
          {/* <select
                        className="form-select"
                        name="location"
                        value={filters.location}
                        onChange={handleFilterChange}
                    >
                        <option value="">Select Location</option>
                        {citiesList.map(city => (
                            <option key={city.id} value={city.id}>{city.city_name}</option>
                        ))}
                    </select>
                    */}
          <select
            className="form-select"
            name="skill_id"
            value={filters.skill_id}
            onChange={handleFilterChange}
          >
            <option value="">Filter by Skills</option>
            {skillList?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.skill_title}
                </option>
              );
            })}
          </select>
          <select
            className="form-select"
            name="job_type"
            value={filters.job_type}
            onChange={handleFilterChange}
          >
            <option value="">Select Job Type</option>
            {job_types.map((jobType, index) => (
              <option key={index} value={jobType.id}>
                {jobType.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>Verification</th>
                  <th>S.No</th>
                  <th onClick={() => handleSort("company_name")}>
                    Company Name {renderSortIcon("company_name")}
                  </th>
                  <th onClick={() => handleSort("job_title")}>
                    Job Title {renderSortIcon("job_title")}
                  </th>
                  <th onClick={() => handleSort("min_experience")}>
                    Experiences {renderSortIcon("min_experience")}
                  </th>
                  <th>Location</th>
                  <th onClick={() => handleSort("job_position")}>
                    Job Position {renderSortIcon("job_position")}
                  </th>
                  <th onClick={() => handleSort("job_ctc")}>
                    Job CTC {renderSortIcon("job_ctc")}
                  </th>
                  <th onClick={() => handleSort("job_details")}>
                    Job Details {renderSortIcon("job_details")}
                  </th>
                  <th onClick={() => handleSort("job_sector")}>
                    Job Sector {renderSortIcon("job_sector")}
                  </th>
                  <th onClick={() => handleSort("job_types")}>
                    Job Type {renderSortIcon("job_types")}
                  </th>
                  <th onClick={() => handleSort("job_publish_schedule")}>
                    Publish Schedule {renderSortIcon("job_publish_schedule")}
                  </th>
                  <th onClick={() => handleSort("application_deadline")}>
                    Application Deadline{" "}
                    {renderSortIcon("application_deadline")}
                  </th>
                  {permission.v ? <th>Actions</th> : null}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((job, index) => (
                    <tr key={index}>
                      <td>{isVerifiedUserHTML(job)}</td>
                      <td>{index + 1}</td>
                      <td>{job.company_name}</td>
                      <td>{job.job_title}</td>
                      <td>
                        {job.min_experience} - {job.max_experience} years
                      </td>
                      <td>{job.locations}</td>
                      <td>{job.job_position}</td>
                      <td>{job.job_ctc}</td>
                      <td>{job.job_details}</td>
                      <td>{job.job_sector == 1 ? "Tech" : "Non-Tech"}</td>
                      <td>
                        {job.job_types
                          ?.split(",")
                          .map((it) => job_types[it - 1].title)
                          .join(", ")}
                      </td>
                      <td>{job.job_publish_schedule.split("T")[0]}</td>
                      <td>{job.application_deadline.split("T")[0]}</td>
                      {permission.v ? (
                        <td>
                          <div className="d-flex justify-content-end">
                            <div>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light mr-2"
                                onClick={(e) => handleApplicantsClick(e, job.id)}
                              >
                                Applicants
                              </Link>
                            </div>
                          <div className="action-buttons d-flex justify-content-end">
                            <Link
                              onClick={(event) =>
                                handleViewClick(event, job.id)
                              }
                              className="btn btn-secondary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-eye"></i>
                            </Link>
                            <Link
                              onClick={(event) =>
                                handleEditClick(event, job.id)
                              }
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => handleDeleteClick(job.id)}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </div>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="100%" className="text-center">
                        {" "}
                        No Record Found.
                      </td>
                    </tr>
                  </>
                )}
                <tr ref={lastJobElementRef} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
