import React from "react";

const SkillMatrix = (props)=>{
    const {data} = props;
    return <>
           
           <div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>SKills</th>
									<th>Number of candidate</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((d, index) => (
									<tr key={index}>
										<td>{d.skill_title}</td>
										<td>{d.candidates}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>    
          </>
}

export default SkillMatrix;