import React,{useState, useEffect } from "react";
import { getAllCities } from "../../api/common";


const LocationMatrix = (props)=>{
    const {data, locations} = props;
	console.log("locations", data, locations);
	
    return <>
           
           <div className="row">
				<div className="col-xl-12">
					<div className="table-responsive">
						<table className="table display mb-4 dataTablesCard Plan-table table-responsive-xl card-table dataTable no-footer" >
							<thead>
								<tr>
									<th>Skills</th>
									{
                                      locations?.map((item)=>{
                                        return <><th>{item}</th></>
                                      })     
                                    }
								</tr>
							</thead>
							<tbody>

							{data?.map((d, index) => (
									<tr key={index}>
										<td>{d.skill_title}</td>
                                        {
                                            d?.locations?.map((item)=>{
                                                return <><td>{item.candidates}</td></>
                                            })
                                        }
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>    
          </>
}

export default LocationMatrix;